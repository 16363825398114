import React from 'react';

const LandingPageDEIComponent = props => {
  const { landingDeiTitle, landingLeadDescription } = props;
  return (
    <div className="full-container">
      <h2 className="landingPage-title">{landingDeiTitle}</h2>
      <p className="landingPage-description">{landingLeadDescription}</p>
    </div>
  );
};
export default LandingPageDEIComponent;
