import React from 'react';
import HeaderComponentLeader from '../components/landingComponents/headerComponentLeader';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Header from '../components/headerComponent';
import AgreedHelpComponent from '../components/homeCompnent/agreedHelpComponent';
import NewFooterComponent from '../components/newFooterComponent';
import RequestADemoComponent from '../components/landingComponents/requestADemoComponent';
import BannerComponent from '../components/landingComponents/bannerComponent';
import DeiImage from '../images/dieForConsultants.png';
import LandingPageDEIComponent from '../components/landingComponents/landingPageDEIComponent';
const DEIConsultantsLandingPage = props => {
  const {
    data: {
      wpPost: {
        seo,
        landingPageLeadSection: {
          leadSubtitle,
          leadTitle,
          leadImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: leadImage }],
            },
          },
        },
        agreedHelpSection: {
          agreedHelpSectionDescription,
          agreedHelpSectionSubtitle,
          agreedHelpSectionTitle,
          agreedHelpSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: agreedHelpSectionImage },
            },
          },
        },
        agreedHelpSavingTimeSection: {
          savingTimeSectionDescription,
          savingTimeSectionTitle,
          savingTimeSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: savingTimeSectionImage },
            },
          },
        },
        beAmazedByWhatYourPeopleProduceSection: {
          beAmazedByWhatYourPeopleProduceSectionDescription,
          beAmazedByWhatYourPeopleProduceSectionTitle,
          beAmazedByWhatYourPeopleProduceSectionImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: beAmazedByWhatYourPeopleProduceSectionImage,
              },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  console.log(title);
  return (
    <Layout seo={seo} title={title}>
      <Header />
      <HeaderComponentLeader
        teamworkTitle={leadTitle}
        teamworkDescription={leadSubtitle}
        isLandingPage={true}
        landingImage={leadImage}
        isLanding={true}
      />
      <BannerComponent
        isLandingPage={false}
        bannerSubtitle="How Agreed makes prioritizing DE&I easier."
      />
      <div className="landingPage-container">
        <LandingPageDEIComponent landingDeiTitle=" How Agreed makes prioritizing DE&I easier." />
        <AgreedHelpComponent
          helpSubtitle={savingTimeSectionTitle}
          helpDescription={savingTimeSectionDescription}
          agreedScreenshots={savingTimeSectionImage}
          isFirst={true}
          isSecond={false}
          isLandingPage={true}
          isDeiLanding={true}
          isLanding={true}
        />
        <AgreedHelpComponent
          helpTitle={agreedHelpSectionTitle}
          helpSubtitle={agreedHelpSectionSubtitle}
          helpDescription={agreedHelpSectionDescription}
          agreedScreenshots={agreedHelpSectionImage}
          isSecond={true}
          isLandingPage={true}
          isDeiLanding={true}
          isLanding={true}
        />
        <AgreedHelpComponent
          helpSubtitle={beAmazedByWhatYourPeopleProduceSectionTitle}
          helpDescription={beAmazedByWhatYourPeopleProduceSectionDescription}
          agreedScreenshots={beAmazedByWhatYourPeopleProduceSectionImage}
          isEnd={true}
          isSecond={false}
          isLandingPage={true}
          isLanding={true}
        />
      </div>
      <RequestADemoComponent
        isLanding={true}
        requestDemoTitle="Schedule a Demo"
        RequestADemoImage={DeiImage}
        isDEILandingPage={true}
      />
      <NewFooterComponent isProduct={true} />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPost(slug: { eq: "dei-for-consultants-landing-page" }) {
      seo {
        metaDesc
        title
      }
      landingPageLeadSection {
        leadButton
        leadSubtitle
        leadTitle
        leadImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      agreedHelpSection {
        agreedHelpSectionButtonText
        agreedHelpSectionDescription
        agreedHelpSectionSubtitle
        agreedHelpSectionTitle
        agreedHelpSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      agreedHelpSavingTimeSection {
        savingTimeSectionButtonText
        savingTimeSectionDescription
        savingTimeSectionTitle
        savingTimeSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      beAmazedByWhatYourPeopleProduceSection {
        beAmazedByWhatYourPeopleProduceSectionButtonTitle
        beAmazedByWhatYourPeopleProduceSectionDescription
        beAmazedByWhatYourPeopleProduceSectionTitle
        beAmazedByWhatYourPeopleProduceSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default DEIConsultantsLandingPage;
